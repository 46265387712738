// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


import "leaflet/dist/leaflet.css"
import * as L from 'leaflet';
import 'leaflet-defaulticon-compatibility';
import "../css/pack_stl.scss";
import "popper.js";
import "bootstrap";
// import "bootstrap-datepicker";
// import "bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
import {decode, encode} from "@msgpack/msgpack";

import 'jquery-ui'
import 'jquery-ui/ui/widgets/tooltip.js'
import 'jquery-ui/themes/base/theme.css'

// require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
// require("@/channels")

var jqeBsTooltip = window.$.fn.tooltip;
window.$.fn.tlp = function(options) {
    jqeBsTooltip.call(this, options);

    this.on("tooltipopen", function(event, ui) {
        $(".ui-helper-hidden-accessible").remove();
    });

    return this;
};

// const datepickerBS = window.$.fn.datepicker;

// import "bootstrap-datepicker/js/locales/bootstrap-datepicker.fr";
// import "bootstrap-datepicker/js/locales/bootstrap-datepicker.es";
// import "bootstrap-datepicker/js/locales/bootstrap-datepicker.ja";
// import "bootstrap-datepicker/js/locales/bootstrap-datepicker.en-GB";
// import "bootstrap-datepicker/js/locales/bootstrap-datepicker.de";
// import "bootstrap-datepicker/js/locales/bootstrap-datepicker.zh-CN";

// import 'jquery-ui-dist/jquery-ui.min.js';
// import 'jquery-ui-touch-punch/jquery.ui.touch-punch.js';
// import 'jquery-ui/ui/i18n/datepicker-fr.js'
// import 'jquery-ui/ui/i18n/datepicker-es.js'
// import 'jquery-ui/ui/i18n/datepicker-ja.js'
// import 'jquery-ui/ui/i18n/datepicker-en-GB.js'
// import 'jquery-ui/ui/i18n/datepicker-de.js'
// import 'jquery-ui/ui/i18n/datepicker-zh-CN.js'

// window.$.fn.datepicker = datepickerBS;

window.moment = require("moment")
window.Chart = require("chart.js")

window.encode = encode;
window.decode = decode;

// moment.locale('fr', {
//     months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
//     monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
//     monthsParseExact : true,
//     weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
//     weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
//     weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
//     weekdaysParseExact : true,
//     longDateFormat : {
//         LT : 'HH:mm',
//         LTS : 'HH:mm:ss',
//         L : 'DD/MM/YYYY',
//         LL : 'D MMMM YYYY',
//         LLL : 'D MMMM YYYY HH:mm',
//         LLLL : 'dddd D MMMM YYYY HH:mm'
//     },
//     calendar : {
//         sameDay : '[Aujourd’hui à] LT',
//         nextDay : '[Demain à] LT',
//         nextWeek : 'dddd [à] LT',
//         lastDay : '[Hier à] LT',
//         lastWeek : 'dddd [dernier à] LT',
//         sameElse : 'L'
//     },
//     relativeTime : {
//         future : 'dans %s',
//         past : 'il y a %s',
//         s : 'quelques secondes',
//         m : 'une minute',
//         mm : '%d minutes',
//         h : 'une heure',
//         hh : '%d heures',
//         d : 'un jour',
//         dd : '%d jours',
//         M : 'un mois',
//         MM : '%d mois',
//         y : 'un an',
//         yy : '%d ans'
//     },
//     dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
//     ordinal : function (number) {
//         return number + (number === 1 ? 'er' : 'e');
//     },
//     meridiemParse : /PD|MD/,
//     isPM : function (input) {
//         return input.charAt(0) === 'M';
//     },
//     // In case the meridiem units are not separated around 12, then implement
//     // this function (look at locale/id.js for an example).
//     // meridiemHour : function (hour, meridiem) {
//     //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
//     // },
//     meridiem : function (hours, minutes, isLower) {
//         return hours < 12 ? 'PD' : 'MD';
//     },
//     week : {
//         dow : 1, // Monday is the first day of the week.
//         doy : 4  // Used to determine first week of the year.
//     }
// });

//require("website/controllers/TrackDetail.class.js")


window.cookies = {
  get: function(name) {
    if (name) {
      var match = document.cookie.match(new RegExp(name + "=(.*?)(?:;|$)"));      
      if (match) {
        return unescape(match[1].replace(/\+/g, "%20"));
      }
    }

    return null;
  },
  create: function(cname, cvalue="", exdays=365){
      let d = new Date();
      d.setDate(d.getDate() + exdays);
      document.cookie = cname + "=" + cvalue + ";" + "expires=" + d.toUTCString() + ";path=/";
  },
  list: function() {
    var matches = document.cookie.split(new RegExp("=|; "));
    if (matches.length > 1) {
      var cookies = new Array(matches.length / 2);
      for (var i = j = 0; j < matches.length; j+=2) {
        cookies[i++] = matches[j]
      }
      return cookies;
    }
  },
  delete: function(name) {
    this.create(name, "" , -1);
  }
}

import "@/website/Application.js"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
